import React from "react"
import { PageProps, Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { timeParse, timeFormat } from "d3-time-format"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  MarkdownRemark,
  MarkdownRemarkEdge,
  DashboardPageQuery,
} from "../../graphql-types"
import { LinearScale } from "@mui/icons-material"

const Index: React.FC<Props> = ({ data }) => {
  const meta = [{ name: "robots", content: "noindex" }]

  const { totalCount, edges } = data.allMdx

  const tagsPostList = edges.flatMap(({ node }) => {
    return node.frontmatter.tags.length ?? ""
  })
  const timeToReadList = edges.flatMap(({ node }) => {
    return node.fields.timeToRead.minutes ?? ""
  })
  const ArrAvg = (arr: Array<T>) => arr.reduce((p, c) => p + c, 0) / arr.length

  return (
    <Layout>
      <SEO title="dashboard for view metadata in this site" meta={meta} />
      <h1>statistics for blog</h1>
      <h2>total pages</h2>
      <div>{totalCount}</div>
      <h2>tags per post(avg.)</h2>
      <div>{ArrAvg(tagsPostList).toFixed(2)}</div>
      <h2>time to read(avg.)</h2>
      <div>{ArrAvg(timeToReadList).toFixed(2)}</div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query DashboardPage {
    allMdx(
      filter: { frontmatter: { tags: { ne: null }, draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            timeToRead {
              words
              minutes
            }
            slug
          }
          frontmatter {
            tags
            date(locale: "JST", formatString: "YYYY-MM")
            title
          }
        }
      }
    }
  }
`
